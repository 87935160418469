import p1 from '../../images/gallery/img1.jpg';
import p2 from '../../images/gallery/img2.jpg';
import p3 from '../../images/gallery/img3.jpg';
import p4 from '../../images/gallery/img4.jpg';
import p5 from '../../images/gallery/img5.jpg';
import p6 from '../../images/gallery/img6.jpg';
import p7 from '../../images/gallery/img7.jpg';
import p9 from '../../images/gallery/img9.jpg';
import p10 from '../../images/gallery/img10.jpg';
import p11 from '../../images/gallery/img11.jpg';
import p12 from '../../images/gallery/img12.jpg';
import p13 from '../../images/gallery/img13.jpg';
import p14 from '../../images/gallery/img14.jpg';
import p15 from '../../images/gallery/img15.jpg';
import p16 from '../../images/gallery/img16.jpg';
import p17 from '../../images/gallery/img17.jpg';
import p18 from '../../images/gallery/img18.jpg';
import p19 from '../../images/gallery/img19.jpg';
import p21 from '../../images/gallery/img21.jpg';
import p22 from '../../images/gallery/img22.jpg';
import p23 from '../../images/gallery/img23.jpg';
import p24 from '../../images/gallery/img24.jpg';
import p25 from '../../images/gallery/img25.jpg';
import p26 from '../../images/gallery/img26.jpg';
import p27 from '../../images/gallery/img27.jpg';
import p28 from '../../images/gallery/img28.jpg';
import p29 from '../../images/gallery/img29.jpg';
import p30 from '../../images/gallery/img30.jpg';
import p31 from '../../images/gallery/img31.jpg';
import p32 from '../../images/gallery/img32.jpg';
import p33 from '../../images/gallery/img33.jpg';
import p34 from '../../images/gallery/img34.jpg';
import p35 from '../../images/gallery/img35.jpg';
import p36 from '../../images/gallery/img36.jpg';
import p37 from '../../images/gallery/img37.jpg';
import p38 from '../../images/gallery/img38.jpg';
import p39 from '../../images/gallery/img39.jpg';
import p40 from '../../images/gallery/img40.jpg';
import p41 from '../../images/gallery/img41.jpg';
import p42 from '../../images/gallery/img42.jpg';
import p43 from '../../images/gallery/img43.jpg';

import p44 from '../../images/notes/nota2a.jpeg';
import p45 from '../../images/notes/nota2b.jpeg';
import p46 from '../../images/notes/nota2c.jpeg';
import p47 from '../../images/notes/nota2d.jpeg';
import p48 from '../../images/notes/nota2e.jpeg';

export const photos = [
  { src: p1, width: 4, height: 3 },
  { src: p2, width: 4, height: 3 },
  { src: p3, width: 4, height: 3 },
  { src: p5, width: 4, height: 3 },
  { src: p6, width: 4, height: 3 },
  { src: p7, width: 4, height: 3 },
  { src: p9, width: 4, height: 3 },
  { src: p10, width: 4, height: 3 },
  { src: p11, width: 4, height: 3 },
  { src: p12, width: 4, height: 3 },
  { src: p13, width: 4, height: 3 },
  { src: p14, width: 4, height: 3 },
  { src: p15, width: 4, height: 3 },
  { src: p16, width: 4, height: 3 },
  { src: p17, width: 4, height: 3 },
  { src: p18, width: 4, height: 3 },
  { src: p19, width: 4, height: 3 },
  { src: p21, width: 4, height: 3 },
  { src: p22, width: 4, height: 3 },
  { src: p23, width: 4, height: 3 },
  { src: p24, width: 4, height: 3 },
  { src: p25, width: 4, height: 3 },
  { src: p26, width: 4, height: 3 },
  { src: p27, width: 4, height: 3 },
  { src: p28, width: 4, height: 3 },
  { src: p29, width: 4, height: 3 },
  { src: p30, width: 4, height: 3 },
  { src: p31, width: 4, height: 3 },
  { src: p32, width: 4, height: 3 },
  { src: p33, width: 4, height: 3 },
  { src: p34, width: 4, height: 3 },
  { src: p35, width: 4, height: 3 },
  { src: p36, width: 4, height: 3 },
  { src: p37, width: 4, height: 3 },
  { src: p38, width: 4, height: 3 },
  { src: p39, width: 4, height: 3 },
  { src: p40, width: 4, height: 3 },
  { src: p41, width: 4, height: 3 },
  { src: p42, width: 4, height: 3 },
  { src: p43, width: 4, height: 3 },
];

export const nota2 = [
  { src: p44, width: 4, height: 3 },
  { src: p45, width: 4, height: 3 },
  { src: p46, width: 4, height: 3 },
  { src: p47, width: 4, height: 3 },
  { src: p48, width: 4, height: 3 },
]