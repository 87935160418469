import React, { useState, useCallback } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeader from '../components/page-header';
import { photos } from '../components/helpers/photos';

import bg from '../images/img5.jpg';

const GalleryPage = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <Layout>
      <SEO title="Galería" />
      <div className="page gallery-page">
        <PageHeader bg={bg} />
        <div className="wrapper content">
          <h1>Galería</h1>
          <p>
            Para nosotros es muy importante el desarrollo integral de nuestros alumnos, es por ello que, además de un excelente nivel académico y ambiente estudiantil, contamos con un gran número de actividades las cuales desarrollarán sus capacidades para formar a los hombres y mujeres del mañana. 
          </p>
          <Gallery photos={photos} onClick={openLightbox} />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photos.map(x => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </div>
    </Layout>
  )
}

export default GalleryPage;